export default defineNuxtPlugin(async (nuxtApp) => {
  if (import.meta.env.DEV) {
    // @ts-expect-error - VueAxe is not typed
    const vueAxe = await import('vue-axe')
    nuxtApp.vueApp.use(vueAxe.default, {
      auto: false,
    })
    nuxtApp.vueApp.component('VueAxePopup', vueAxe.VueAxePopup)
  }
})
