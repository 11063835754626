import { datadogRum } from '@datadog/browser-rum'

export default defineNuxtPlugin(
  (app) => {
    if (import.meta.env.PROD) {
      datadogRum.init({
        ...app.$config.public.datadog,
        defaultPrivacyLevel: 'mask-user-input',
        sessionSampleRate: 100,
      })
    }
  },
)
