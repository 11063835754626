import { datadogRum } from '@datadog/browser-rum'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = handler.bind(null, 'errorHandler')

  nuxtApp.hook('app:error', handler.bind(null, 'app:error'))

  nuxtApp.hook('vue:error', handler.bind(null, 'vue:error'))
})

function handler(
  source: 'app:error' | 'vue:error' | 'errorHandler',
  error: unknown,
) {
  if (import.meta.client) {
    datadogRum.addError(error, {
      source,
    })
  }
  else {
    console.error(error, { source })
  }
}
