<script setup lang="ts">
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'

const previewMode = usePreviewMode()

useHead({
  script: previewMode.enabled.value
    ? [
        {
          src: 'https://app.crystallize.com/static/frontend-preview-listener.js',
          defer: true,
        },
      ]
    : [],
})
</script>

<template>
  <NuxtRouteAnnouncer />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <VueQueryDevtools button-position="bottom-left" />
  <ClientOnly>
    <VueAxePopup />
  </ClientOnly>
</template>

<style>
.va-btn {
  padding: 0.5rem !important;
  width: fit-content !important;
  height: fit-content !important;
}
</style>
