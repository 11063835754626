import * as cookie from 'cookie-es'

/**
 * Middleware that runs only on the server, and refreshes the user's access token if it has expired.
 *
 * It will update the relevant cookies, as well as pass them to subsequent requests using the event context.
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const { session, loggedIn } = useUserSession()

  if (
    import.meta.client
      || to.path === '/logg-inn'
      || !loggedIn.value
      || (session.value?.expiresAt ?? 0) >= Date.now()
  ) {
    return
  }

  const { cookieNames } = useRuntimeConfig().public.session

  const tokenCookie = useCookie(cookieNames.token)

  const userCookie = useCookie(cookieNames.user)

  const event = useRequestEvent()

  try {
    await $fetch('/api/auth/refresh', {
      // Pass event headers during SSR, so that cookies are present
      headers: event?.headers,
      retry: false,
      onResponse({ response }) {
        if (import.meta.client) {
          // On the client, cookie changes are handled automatically.
          return
        }

        // If the token or user cookies have been changed (which we expect after a token refresh), we pass them to subsequent requests using the event context
        // In addition, we update the cookie values manually, as during SSR, this will not happen by default
        const cookies: string[] = []
        for (const setCookie of response.headers.getSetCookie()) {
          const { name, value } = cookie.parseSetCookie(setCookie)
          if ((name === cookieNames.token || name === cookieNames.user)) {
            if (name === cookieNames.token) {
              tokenCookie.value = value
            }
            else if (name === cookieNames.user) {
              userCookie.value = value
            }

            if (value) {
              cookies.push(cookie.serialize(name, value))
            }
          }
        }

        if (event?.context) {
          event.context.updatedCookies = cookies
        }
      },
    })
  }
  catch (e) {
    console.error(e)
    // This probably means the user is not authenticated anymore, but that should be handled elsewhere, either by middleware or redirect after a failed fetch.
  }
})
