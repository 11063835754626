import type { CoreApi } from '#shared/core'
import { array, nullish, object, parse, string } from 'valibot'

const contextSchema = nullish(
  object({
    updatedCookies: nullish(
      array(string()),
    ),
  }),
)

/**
 * Provides fetchers for Core APIs.
 */
export default defineNuxtPlugin((app) => {
  const headers = useRequestHeaders(['cookie'])

  const event = useRequestEvent()

  const createFetcher = (api: CoreApi) => $fetch.create({
    baseURL: `/api/core/${api}`,

    // Ensure cookies are passed to the server route during SSR.
    headers,

    async onRequest({ options }) {
      // Check if updated cookies has been provided through the event context, and if so, update the headers before sending the request.
      const context = parse(contextSchema, event?.context)
      if (context?.updatedCookies?.length) {
        options.headers.set('cookie', context.updatedCookies.join('; '))
      }
    },

    async onResponseError({ response }) {
      // If we receive a 401 response, which should only happen if the refresh token has expired, redirect to the login page.
      if (response.status === 401) {
        await app.runWithContext(() => navigateTo('/logg-inn'))
      }
    },
  })

  return {
    provide: {
      coreApi: {
        ecommerce: createFetcher('ecommerce'),
        external: createFetcher('external'),
      },
    },
  }
})
